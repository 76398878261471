import type { RouteRecordRaw } from 'vue-router';

import { routes as clientErrorRoutes } from '@abaninja/feature-client-errors';
import { routes as abaclikRoutes } from '@abaninja/page-swiss21-abaclik';
import { routes as accountRoutes } from '@abaninja/page-swiss21-accounts';
import { routes as billingRoutes } from '@abaninja/page-swiss21-billing';
import { routes as dashboardRoutes } from '@abaninja/page-swiss21-dashboard';
import { routes as deepcloudRoutes } from '@abaninja/page-swiss21-deepcloud';
import { routes as inboxRoutes } from '@abaninja/page-swiss21-inbox';
import { routes as inviteRoutes } from '@abaninja/page-swiss21-invite';
import { routes as logRoutes } from '@abaninja/page-swiss21-logs';
import { routes as nextClassroomRoutes } from '@abaninja/page-swiss21-next-classroom';
import { routes as onboardingRoutes } from '@abaninja/page-swiss21-onboarding';
import { routes as peppershopRoutes } from '@abaninja/page-swiss21-peppershop';
import { routes as settingRoutes } from '@abaninja/page-swiss21-settings';
import { routes as subscriptionRoutes } from '@abaninja/page-swiss21-subscription';
import { routes as supportRoutes } from '@abaninja/page-swiss21-support';
import { createOAuthCbRoutes } from '@abaninja/util-keycloak';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('../../layout/GuardedIndex.vue'),
    children: [
      {
        path: '',
        meta: {
          needsAccount: true,
        },
        children: [
          {
            path: '',
            name: 'main-routes',
            component: () => import('../../layout/Default.vue'),
            children: [
              ...dashboardRoutes,
              ...subscriptionRoutes,
              ...billingRoutes,
            ],
          },
          {
            path: '',
            component: () => import('../../layout/Default.vue'),
            children: [
              ...peppershopRoutes,
              ...abaclikRoutes,
              ...accountRoutes,
              ...settingRoutes,
              ...inboxRoutes,
              ...deepcloudRoutes,
              ...supportRoutes,
              ...logRoutes,
            ],
          },
        ],
      },

      {
        path: '',
        component: () => import('../../layout/MainView.vue'),
        children: [
          ...onboardingRoutes,
          ...nextClassroomRoutes,
          ...inviteRoutes,
          ...clientErrorRoutes,
        ],
      },
    ],
  },

  // handle keycloak
  ...createOAuthCbRoutes('/oauthcb', 'dashboard'),

  {
    path: '/:pathMatch(.*)*',
    redirect: (to) => ({ name: '404', query: { toPath: to.fullPath } }),
  },
];
